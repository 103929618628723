<template>
  <div>
    <div class="coverDetail">
      <h2>X | Matematika</h2>
      <div>Session 20/36 | Algebra Part 1</div>
    </div>
    <div class="contentDetail">
      <a-row type="flex" class="menuSchedule">
        <a-col :span="20">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="materials" class="subMenuDetail">MATERIALS</a-menu-item>
            <a-menu-item key="assignments" class="subMenuDetail">ASSIGNMENTS</a-menu-item>
          </a-menu>
        </a-col>
        <a-col :span="4" style="align-self: flex-end">
          <a-button @click.prevent="cancel()" shape="round" type="secondary">CANCEL</a-button>
          <a-button shape="round" style="background-color: #42B883; color: white;" icon="save">SAVE</a-button>
        </a-col>
      </a-row>
      <div>
        <template v-if="current[0] === 'materials'">
          <a-divider orientation="left">
            <h3>Video</h3>
          </a-divider>
          <div class="content">
            <div>
              <a-row :gutter="16">
                <a-col :span="6">
                  <iframe
                    width="165"
                    height="90"
                    src="https://drive.google.com/file/d/1bTmrlj5baFQfZZlC-tvkPjBssa3uCUgj/preview"
                  ></iframe>
                </a-col>
                <a-col :span="16">
                  <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus eveniet dolores laborum cumque natus laboriosam?</h4>
                  <p>08:32</p>
                </a-col>
                <a-col :span="2">
                  <a-button
                    type="danger"
                    icon="close"
                    :size="size"
                    style="width:48px; height:48px;"
                  ></a-button>
                </a-col>
              </a-row>
              <hr />
            </div>
            <div>
              <a-row :gutter="16">
                <a-col :span="22">
                  <a-input placeholder="Paste video's youtube link here" style="height:48px;" />
                </a-col>
                <a-col :span="2">
                  <a-button
                    type="primary"
                    icon="plus"
                    :size="size"
                    style="width:48px; height:48px;"
                  ></a-button>
                </a-col>
              </a-row>
            </div>
          </div>
          <a-divider orientation="left">
            <h3>Reading Material</h3>
          </a-divider>
          <div class="content">
            <wysiwyg v-model="myHTML" />
          </div>
          <a-row type="flex" align="middle" justify="end">
            <a-col :span="20">
              <a-divider orientation="left">
                <h3>Attached Materials</h3>
              </a-divider>
            </a-col>
            <a-col :span="4">
              <a-button
                class="btnDownload"
                shape="round"
                type="primary"
                icon="download"
                @click.prevent="downloadAll"
              >DOWNLOAD ALL</a-button>
            </a-col>
          </a-row>
          <div class="content">
            <div>
              <a-row :gutter="16">
                <a-col :span="20">
                  <a-input value="document_algebra_part_1.pptx" read-only style="height:48px;" />
                </a-col>
                <a-col :span="2">
                  <a-button icon="download" :size="size" style="width:48px; height:48px;"></a-button>
                </a-col>
                <a-col :span="2">
                  <a-button
                    type="danger"
                    icon="close"
                    :size="size"
                    style="width:48px; height:48px;"
                  ></a-button>
                </a-col>
              </a-row>
              <hr />
            </div>
            <div>
              <a-row :gutter="16">
                <a-col :span="22">
                  <a-input
                    placeholder="Upload additional documents or files for this session"
                    style="height:48px;"
                  />
                </a-col>
                <a-col :span="2">
                  <a-button
                    type="primary"
                    icon="plus"
                    :size="size"
                    style="width:48px; height:48px;"
                  ></a-button>
                </a-col>
              </a-row>
            </div>
          </div>
        </template>
        <template v-if="current[0] === 'assignments'">
          <a-divider orientation="left">
            <h3>Assignment Info</h3>
          </a-divider>
          <a-row :gutter="40">
            <a-col :span="12">
              <a-form-item label="Type">
                <a-select default-value="file_upload" style="width: 100%;" :size="size">
                  <a-select-option value="file_upload">
                    <a-icon type="paper-clip" class="mr-2" />File Upload
                  </a-select-option>
                  <a-select-option value="essay">
                    <a-icon type="align-left" class="mr-2" />Essay
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Due Date">
                <a-date-picker
                  placeholder="Select due date"
                  style="width: 100%; height:auto;"
                  :size="size"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-divider orientation="left">
            <h3>Details</h3>
          </a-divider>
          <div class="content">
            <a-input
              placeholder="Write task and instructions of the assignment"
              style="height:100px;"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      current: ['materials'],
      size: 'large',
    }
  },
  methods: {
    cancel() {
      const id = this.$route.params.id
      this.$router.push({ name: 'Detail Schedule Teacher', params: id })
    },
  },
}
</script>
<style scoped>
.coverDetail {
  width: 100%;
  min-height: 145px;
  background-color: #008080;
  border-radius: 10px;
  padding: 40px;
  color: #ffffff;
}

/* h2 {
  color: #ffffff;
} */

.menuSchedule {
  margin-bottom: 35px;
}

.contentDetail {
  width: 100%;
  min-height: 20vh;
  background-color: white;
  padding: 40px;
  z-index: 11;
  margin-top: -10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #0419301a;
  margin-left: 1px;
}

.content {
  padding: 10px 225px;
}
</style>
