<template>
  <div class="switchClass">
    <a-switch
      :default-checked="currentSlot.slot.kela.id === kelas.id"
      :checked="isAssigned"
      @change="changeChecked"
      :disabled="isThisSession"
    />
    <span>{{this.tanggal}}</span>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  props: ['kelas', 'slotClass'],
  computed: {
    currentSlot() {
      return this.$store.state.slot.currentSlot
    },
    tanggal() {
      return moment(this.slotClass.sesis[0].tanggal_sesi).format('DD MMMM YYYY')
    },
    isThisSession() {
      return this.slotClass.sesis[0].id === Number(this.$route.params.id)
    },
  },
  data() {
    return {
      isAssigned: false,
    }
  },
  methods: {
    changeChecked(value) {
      this.isAssigned = value
      this.$emit('changeChecked', {
        isAssigned: value,
        kelas: this.kelas,
        id_sesi: this.slotClass.sesis[0].id,
      })
    },
  },
  mounted() {
    // console.log(this.slotClass.sesis[0].id, this.$route.params.id)
    if (this.isThisSession) {
      this.isAssigned = true
    }
  },
}
</script>
<style lang="scss">
  .switchClass {
    .ant-switch {
      margin-right: 5px;
    }
  }
</style>
