var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assignmentSchedule"},[_c('div',{staticStyle:{"margin-bottom":"30px"}},[_c('a-divider',{attrs:{"orientation":"left"}},[_c('h3',[_vm._v("Assignment Info")])]),_c('div',{staticClass:"paddingLeft"},[_c('a-row',[_c('a-col',{class:[_vm.isMobile ? 'text-center' : ''],attrs:{"span":_vm.isMobile ? 24 : !_vm.assignmentStudent ? 8 : 10}},[_c('label',{staticStyle:{"margin-bottom":"2% !important"}},[_c('b',[_vm._v("Type")])]),(_vm.taskObj)?[_c('p',{style:({ color: _vm.taskObj.type == null ? 'gray' : '#1B55E3' })},[_c('a-icon',{attrs:{"type":_vm.taskObj.icon}}),_vm._v(" "+_vm._s(_vm.taskObj.type == null ? "No Assignment" : _vm.taskObj.type)+" ")],1)]:_vm._e()],2),_c('a-col',{class:[_vm.isMobile ? 'text-center' : '', _vm.isMobile ? 'mt-3' : ''],attrs:{"span":_vm.isMobile ? 24 : !_vm.assignmentStudent ? 8 : 14}},[(!_vm.assignmentStudent || !_vm.assignmentStudent.tugas_nilai)?[_c('label',{staticStyle:{"margin-bottom":"2% !important"}},[_c('b',[_vm._v("Due Date")])]),_c('p',[_vm._v(" "+_vm._s(this.session.tugas_deadline2 == null ? "No Due Date" : _vm.secondDueDateAndTime)+" ")])]:_c('a-row',[_c('a-col',{attrs:{"span":5}},[_c('label',{staticStyle:{"margin-bottom":"2% !important"}},[_c('b',[_vm._v("Result")])]),_c('h1',{style:({
                  color: _vm.color(_vm.assignmentStudent.tugas_nilai),
                })},[_vm._v(" "+_vm._s(_vm.assignmentStudent.tugas_nilai)+" ")])]),_c('a-col',{attrs:{"span":19}},[_c('label',{staticStyle:{"margin-bottom":"2% !important"}},[_c('b',[_vm._v("Feedback")])]),_c('p',[_vm._v(_vm._s(_vm.assignmentStudent.feedback))])])],1)],2),(!_vm.assignmentStudent)?_c('a-col',{attrs:{"span":_vm.isMobile ? 24 : 8}},[_c('label',{staticStyle:{"margin-bottom":"2% !important"}},[_c('b',[_vm._v("Published On")])]),_c('p',[_vm._v(" "+_vm._s(!this.session.publishedOn ? "-" : _vm.publishedOnRevised)+" ")])]):_vm._e()],1)],1)],1),_c('div',{staticStyle:{"margin-bottom":"30px"}},[_c('a-divider',{attrs:{"orientation":"left"}},[_c('h3',[_vm._v("Details")])]),_c('div',{class:[_vm.taskType ? 'textAssignment' : '']},[(_vm.taskType === 'File Upload')?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.session.tugas_deskripsi)}}),(!_vm.isTeacher)?_c('a-form-item',{staticStyle:{"margin-top":"5%"}},[_c('h4',[_vm._v("Your Answer")]),(_vm.statusFileDone)?_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"xs":24,"lg":20}},[_c('a-input',{staticStyle:{"height":"48px"},attrs:{"value":_vm.fileAnswerDone.tugas_filename,"read-only":""}})],1),_c('a-col',{attrs:{"xs":24,"lg":2}},[_c('a-button',{staticStyle:{"width":"100%","height":"48px"},attrs:{"icon":"download"},on:{"click":function($event){return _vm.downloadFile(
                    _vm.fileAnswerDone.tugas_filepath,
                    _vm.fileAnswerDone.tugas_filename
                  )}}})],1),_c('a-col',{attrs:{"xs":24,"lg":2}},[_c('a-button',{staticStyle:{"width":"100%","height":"48px"},attrs:{"type":"danger","icon":"close","loading":_vm.loadingDelete,"disabled":_vm.assignmentStudent.tugas_nilai != null},on:{"click":function($event){$event.preventDefault();return _vm.deleteFile(
                    _vm.fileAnswerDone.id,
                    _vm.fileAnswerDone.tugas_filepath,
                    _vm.fileAnswerDone.tugas_filename
                  )}}})],1)],1):_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.addFile($event)}}},[_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"xs":24,"lg":20}},[_c('a-input',{ref:"file",staticClass:"pt-4 pb-5",staticStyle:{"height":"48px"},attrs:{"type":"file","placeholder":"Upload additional documents or files for this session"},on:{"change":_vm.selectFile}})],1),_c('a-col',{attrs:{"xs":24,"lg":3}},[_c('a-button',{staticStyle:{"width":"100%","height":"70px"},attrs:{"type":!_vm.fileAnswer ? 'secondary' : 'primary',"icon":"upload","loading":_vm.loadingAdd,"disabled":!_vm.fileAnswer},on:{"click":function($event){$event.preventDefault();return _vm.addFile()}}})],1)],1)],1)],1):_c('div')],1):(_vm.taskType === 'Multiple Choice')?_c('div',{staticClass:"textAssignment"},[(!_vm.isTeacher)?_c('h3',[_vm._v("Your Answer")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.session.tugas_deskripsi)}}),_vm._l((_vm.session.pg_soals),function(item,index){return _c('div',{key:index,staticClass:"mt-5"},[_c('a-row',[_c('a-col',{attrs:{"span":2}},[_c('h3',[_vm._v(_vm._s(_vm.numberQuestion(index))+".")])]),_c('a-col',{attrs:{"span":22}},[_c('p',{staticClass:"mb-3",domProps:{"innerHTML":_vm._s(item.soal)}}),_c('div',{staticClass:"d-flex flex-column mb-3"},[_c('div',{staticClass:"d-flex flex-row align-items-start"},[_c('div',[(_vm.isTeacher)?_c('a-button',{staticClass:"d-inline-block",attrs:{"type":item.jawaban === 'A' ? 'primary' : 'default',"shape":"round"}},[_vm._v("A")]):_vm._e(),(_vm.doAssignment)?_c('a-button',{staticClass:"d-inline-block",attrs:{"type":_vm.multipleChoiceAnswer[index] === 'A'
                          ? 'primary'
                          : 'default',"shape":"round"},on:{"click":function($event){$event.preventDefault();return _vm.answerQuestion(index, 'A')}}},[_vm._v("A")]):(!_vm.isTeacher)?_c('span',{class:_vm.doAssignment ? 'd-none' : 'd-inline-block'},[_vm._v("A.")]):_vm._e()],1),_c('div',{staticClass:"ml-3"},[_c('span',{domProps:{"innerHTML":_vm._s(item.pilihan_a)}}),(
                        !_vm.isTeacher &&
                        !_vm.doAssignment &&
                        _vm.multipleChoiceAnswer[index] === 'A'
                      )?_c('span',{staticClass:"text-success"},[_vm._v(" (Your Answer)")]):_vm._e()])])]),_c('div',{staticClass:"d-flex flex-column mb-3"},[_c('div',{staticClass:"d-flex flex-row align-items-start"},[_c('div',[(_vm.isTeacher)?_c('a-button',{staticClass:"d-inline-block",attrs:{"type":item.jawaban === 'B' ? 'primary' : 'default',"shape":"round"}},[_vm._v("B")]):_vm._e(),(_vm.doAssignment)?_c('a-button',{staticClass:"d-inline-block",attrs:{"type":_vm.multipleChoiceAnswer[index] === 'B'
                          ? 'primary'
                          : 'default',"shape":"round"},on:{"click":function($event){$event.preventDefault();return _vm.answerQuestion(index, 'B')}}},[_vm._v("B")]):(!_vm.isTeacher)?_c('span',{class:_vm.doAssignment ? 'd-none' : 'd-inline-block'},[_vm._v("B.")]):_vm._e()],1),_c('div',{staticClass:"ml-3"},[_c('span',{domProps:{"innerHTML":_vm._s(item.pilihan_b)}}),(
                        !_vm.isTeacher &&
                        !_vm.doAssignment &&
                        _vm.multipleChoiceAnswer[index] === 'B'
                      )?_c('span',{staticClass:"text-success"},[_vm._v(" (Your Answer)")]):_vm._e()])])]),_c('div',{staticClass:"d-flex flex-column mb-3"},[_c('div',{staticClass:"d-flex flex-row align-items-start"},[_c('div',[(_vm.isTeacher)?_c('a-button',{staticClass:"d-inline-block",attrs:{"type":item.jawaban === 'C' ? 'primary' : 'default',"shape":"round"}},[_vm._v("C")]):_vm._e(),(_vm.doAssignment)?_c('a-button',{staticClass:"d-inline-block",attrs:{"type":_vm.multipleChoiceAnswer[index] === 'C'
                          ? 'primary'
                          : 'default',"shape":"round"},on:{"click":function($event){$event.preventDefault();return _vm.answerQuestion(index, 'C')}}},[_vm._v("C")]):(!_vm.isTeacher)?_c('span',{class:_vm.doAssignment ? 'd-none' : 'd-inline-block'},[_vm._v("C.")]):_vm._e()],1),_c('div',{staticClass:"ml-3"},[_c('span',{domProps:{"innerHTML":_vm._s(item.pilihan_c)}}),(
                        !_vm.isTeacher &&
                        !_vm.doAssignment &&
                        _vm.multipleChoiceAnswer[index] === 'C'
                      )?_c('span',{staticClass:"text-success"},[_vm._v(" (Your Answer)")]):_vm._e()])])]),_c('div',{staticClass:"d-flex flex-column mb-3"},[_c('div',{staticClass:"d-flex flex-row align-items-start"},[_c('div',[(_vm.isTeacher)?_c('a-button',{staticClass:"d-inline-block",attrs:{"type":item.jawaban === 'D' ? 'primary' : 'default',"shape":"round"}},[_vm._v("D")]):_vm._e(),(_vm.doAssignment)?_c('a-button',{staticClass:"d-inline-block",attrs:{"type":_vm.multipleChoiceAnswer[index] === 'D'
                          ? 'primary'
                          : 'default',"shape":"round"},on:{"click":function($event){$event.preventDefault();return _vm.answerQuestion(index, 'D')}}},[_vm._v("D")]):(!_vm.isTeacher)?_c('span',{class:_vm.doAssignment ? 'd-none' : 'd-inline-block'},[_vm._v("D.")]):_vm._e()],1),_c('div',{staticClass:"ml-3"},[_c('span',{domProps:{"innerHTML":_vm._s(item.pilihan_d)}}),(
                        !_vm.isTeacher &&
                        !_vm.doAssignment &&
                        _vm.multipleChoiceAnswer[index] === 'D'
                      )?_c('span',{staticClass:"text-success"},[_vm._v(" (Your Answer)")]):_vm._e()])])])])],1)],1)})],2):(_vm.taskType === 'Essay')?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.session.tugas_deskripsi)}}),(!_vm.isTeacher)?_c('div',[_c('h4',[_vm._v("Your Answer")]),_c('br'),(_vm.doAssignment)?_c('div',{staticClass:"ckEditorContainer"},[_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig},on:{"input":_vm.changedEssayAnswer},model:{value:(_vm.essayAnswer),callback:function ($$v) {_vm.essayAnswer=$$v},expression:"essayAnswer"}})],1):_c('div',{domProps:{"innerHTML":_vm._s(_vm.essayAnswer)}})]):_vm._e()]):_c('div',[_c('EmptyState',{attrs:{"mobile":_vm.isMobile,"heading":"No Assignment","description":"There is no assignment on this session."}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }