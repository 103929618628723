<template>
  <div v-if="currentSlot.slot" class="detailSesi">
    <a-modal
      centered
      :visible="modalVisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Update"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" />UPDATE SESSION
      </h3>
      <p class="mb-2">
        Are you sure to update your session? Your data before will be lost once
        you update.
      </p>
      <small class="text-danger" v-if="!this.sesiProperty.publishedOn && this.sesiProperty.tipe_assignment">NOTE: If you did not fill 'published on', your task automatically will be assigned to your student.</small>
    </a-modal>
    <div id="coverDetail">
      <!-- Sebelum lempar props : {{currentSlot.id}} -->
      <h2>
        {{ currentSlot.slot.kela.tingkat }} -
        {{ currentSlot.slot.kela.simbol }} |
        {{ currentSlot.slot.mata_pelajaran.nama }}
      </h2>
      <div>
        Session {{ currentSlot.week.week }} / 24 |
        {{ currentSlot.topik.nama ? topik(currentSlot.topik.nama) : "-" }}
        | {{ sessionDate }}, {{ sessionTime }}
      </div>
    </div>
    <div
      v-if="isInSubjectHours && liveClass.zoom_setting"
      class="countdownTImer"
    >
      <h4>SESSION TIME LEFT</h4>
      <h2>{{ formattedDuration }}</h2>
    </div>
    <div id="contentDetail">
      <a-row type="flex" class="menuSchedule">
        <a-col :span="!isDesktop ? 24 : !isEdit ? 21 : 20">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="live class" class="subMenuDetail">
              <a-icon
                type="video-camera"
                style="color: #ff0000; font-size: 22px"
                v-if="isLive && isNow"
              />
              {{ isLive && isNow ? "LIVE CLASS" : "DETAIL CLASS" }}
            </a-menu-item>
            <a-menu-item key="materials" class="subMenuDetail"
              >MATERIALS</a-menu-item
            >
            <a-menu-item key="assignments" class="subMenuDetail"
              >ASSIGNMENTS</a-menu-item
            >
          </a-menu>
        </a-col>
        <!-- <a-col :span="1" v-if="isEdit"></a-col> -->
        <a-col
          :span="!isDesktop ? 24 : !isEdit ? 3 : 4"
          :class="[!isDesktop ? 'mt-5' : '']"
          :style="{
            display: 'flex',
            'justify-content': !isDesktop ? 'center' : 'flex-end',
            'align-self': !isDesktop ? 'center' : 'flex-end',
          }"
        >
          <a-button
            v-if="!isEdit"
            @click.prevent="
              () => {
                isEdit = true;
              }
            "
            shape="round"
            type="primary"
            icon="edit"
            >EDIT SESSION</a-button
          >
          <div
            v-else
            style="margin-left: 7%; display: flex; justify-content: flex-end"
          >
            <a-button
              @click.prevent="cancelEdit"
              style="margin-right: 5%"
              shape="round"
              :loading="cancelEditLoading"
              icon="edit"
              type="danger"
              >CANCEL</a-button
            >
            <form
              @submit.prevent="updateDetailSesi"
              enctype="multipart/form-data"
              style="display: inline-block"
            >
              <a-button
                htmlType="submit"
                shape="round"
                icon="save"
                style="background-color: #41b883; color: white"
                >SAVE</a-button
              >
            </form>
          </div>
        </a-col>
      </a-row>
      <div>
        <!-- <transition-group name="slide-fadein-up"> -->
        <LiveClass
          key="1"
          v-if="liveClass.zoom_setting"
          v-show="current[0] === 'live class' && !isEdit"
          :liveClass="liveClass"
          :attendances="attendances"
          :isStudent="false"
          :isNow="isNow"
        />
        <LiveClassEdit
          key="2"
          :liveClass="liveClass"
          v-show="liveClass && current[0] === 'live class' && isEdit"
          @updatedLive="cekLive"
          :isEdit="isEdit"
        />
        <Materials
          key="3"
          v-show="materials && current[0] === 'materials' && !isEdit"
          :materials="materials"
          :videoUrls="videoUrls"
          :fileMaterials="fileMaterials"
        />
        <MaterialsEdit
          key="4"
          :session="currentSlot"
          :materials="materials"
          :videoUrls="videoUrls"
          :fileMaterials="fileMaterials"
          v-show="materials && current[0] === 'materials' && isEdit"
          @updatedMaterial="cekMaterial"
          :isEdit="isEdit"
        />
        <Assignments
          key="5"
          v-show="currentSlot.slot && current[0] === 'assignments' && !isEdit"
          :session="currentSlot"
          :isTeacher="true"
          :taskType="currentSlot.tipe_assignment"
        />
        <AssignmentsEdit
          key="6"
          :session="currentSlot"
          :attendances="attendances"
          v-show="currentSlot.slot && current[0] === 'assignments' && isEdit"
          :isTeacher="true"
          @updatedAssignment="cekAssignment"
          :isEdit="isEdit"
          :daftarKelas="daftarKelas"
        />
        <!-- </transition-group> -->
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
// import axios from 'axios'
import LiveClass from '@/components/app/DetailSchedule/LiveClass'
import Materials from '@/components/app/DetailSchedule/Materials'
import Assignments from '@/components/app/DetailSchedule/Assignments'
import AssignmentsEdit from '@/components/app/DetailSchedule/EditSession/Assignments'
import MaterialsEdit from '@/components/app/DetailSchedule/EditSession/Materials'
import LiveClassEdit from '@/components/app/DetailSchedule/EditSession/Live'
// import $ from 'jquery'
// import { ZoomMtg } from '@zoomus/websdk'

export default {
  components: {
    LiveClass,
    Materials,
    Assignments,
    AssignmentsEdit,
    MaterialsEdit,
    LiveClassEdit,
  },
  data() {
    return {
      idWeek: null,
      isEdit: false,
      isClicked: false,
      liveClass: {},
      sesiProperty: {},
      topikProperty: {},
      attendances: [],
      modalVisible: false,
      confirmLoading: false,
      time: moment(),
      nextTime: moment(),
      duration: 0,
      formattedDuration: '00:00:00',
      cancelEditLoading: false,
      daftarKelas: [],
    }
  },
  mounted() {
    // this.makeDuration()
  },
  methods: {
    handleOk(e) {
      const idSession = this.$route.params.id
      Object.assign(this.sesiProperty, {
        id: idSession,
      })
      Object.assign(this.topikProperty, {
        id: this.materials.id,
      })

      if (this.sesiProperty.tipe_assignment === '') {
        this.sesiProperty.tipe_assignment = null
      }

      if (this.sesiProperty.tugas_deadline2 === 'Invalid date') {
        this.sesiProperty.tugas_deadline2 = null
      }

      // console.log('SESI', this.sesiProperty)
      // console.log('TOPIK', this.topikProperty)
      this.confirmLoading = true
      this.$store.dispatch('slot/PUT_DETAIL_SESI', {
        data: [this.sesiProperty, this.topikProperty],
      })
        .then(isSuccess => {
          // console.log(this.sesiProperty.tugas_deadline2)
          setTimeout(() => {
            this.modalVisible = false
            this.confirmLoading = false
            this.isEdit = false
            if (isSuccess) {
              this.$notification.success({
                message: 'Successfully update session',
              })
            } else {
              this.$notification.error({
                message: 'Sorry.',
                description: 'Session can\'t be updated right now. Try again later.',
              })
            }
          }, 2000)
        })
      // console.log('OK')
      // const idSession = this.$route.params.id
      // e.preventDefault()
    },
    cancelEdit() {
      this.$store.dispatch('slot/FETCH_CURRENT_SLOT', { idSession: this.$route.params.id })
      this.cancelEditLoading = true
      setTimeout(() => {
        this.isEdit = false
        this.cancelEditLoading = false
      }, 2000)
    },
    handleCancel(e) {
      // e.preventDefault()
      this.modalVisible = false
    },
    makeDuration() {
      const time = moment(`${this.currentSlot.tanggal_sesi} ${this.currentSlot.slot.model_jadwal.jam_mulai}`, 'YYYY-MM-DD HH:mm').locale('id')
      const nextTime = moment(time, 'HH:mm').add(this.currentSlot.slot.model_jadwal.durasi * this.currentSlot.slot.jam_pelajaran, 'minutes').locale('id')
      const currentTime = moment().locale('id')
      const diffTime = nextTime - currentTime
      this.duration = moment.duration(diffTime, 'milliseconds')
      // console.log(this.duration)
      const timerInterval = setInterval(() => {
        if (this.duration < 0) {
          clearInterval(timerInterval)
        } else {
          this.duration = moment.duration(this.duration - 1000, 'milliseconds')
          this.formattedDuration = `${this.duration.hours() >= 10 ? '' : '0'}${this.duration.hours()}:${this.duration.minutes() >= 10 ? '' : '0'}${this.duration.minutes()}:${this.duration.seconds() >= 10 ? '' : '0'}${this.duration.seconds()}`
        }
      }, 1000)
      // return this.nextTime.diff(this.time, 'miliseconds')
    },
    findWeekSlot(idSlot) {
      const { slots } = this.$store.state.slot
      slots.map(slot => {
        if (slot.id === this.$route.params.id) {
          this.idWeek = slot.id
        }
      })
    },
    topik(text) {
      let string = ''
      if (text.length > 25) {
        string = text.substring(0, 50) + '...'
      } else {
        string = text
      }
      return string
    },
    cekLive(value) {
      this.sesiProperty.zoom_setting = value
    },
    cekMaterial(value) {
      this.topikProperty.nama = value.nama
      this.topikProperty.sub_topik = value.subTopik
      this.topikProperty.brief = value.brief
      this.topikProperty.video_url1 = value.videos[0] ? value.videos[0] : null
      this.topikProperty.video_url2 = value.videos[1] ? value.videos[1] : null
      this.topikProperty.video_url3 = value.videos[2] ? value.videos[2] : null
      this.topikProperty.video_url4 = value.videos[3] ? value.videos[3] : null
      this.topikProperty.video_url5 = value.videos[4] ? value.videos[4] : null
    },
    cekAssignment(value) {
      this.sesiProperty.tipe_assignment = value.tipe_assignment
      this.sesiProperty.tugas_deadline2 = value.tugas_deadline2 ? moment(value.tugas_deadline2).locale('id').format('YYYY-MM-DD HH:mm') : null
      this.sesiProperty.tugas_deskripsi = value.tugas_deskripsi
      this.sesiProperty.pgSoalBundle = value.questions
      this.sesiProperty.classList = value.classList
      this.sesiProperty.publishedOn = value.publishedOn ? moment(value.publishedOn).locale('id').format('YYYY-MM-DD HH:mm') : null
    },
    updateDetailSesi() {
      const deadlineData = moment(this.currentSlot.tugas_deadline2, 'YYYY-MM-DD HH:mm').locale('id').add(7, 'hours').format('YYYY-MM-DD HH:mm')
      const publishedOnData = moment(this.currentSlot.publishedOn, 'YYYY-MM-DD HH:mm').locale('id').add(7, 'hours').format('YYYY-MM-DD HH:mm')
      // console.log(moment(waktu, 'YYYY-MM-DD HH:mm'), moment(this.sesiProperty.tugas_deadline2, 'YYYY-MM-DD HH:mm'), this.currentSlot.tugas_deadline2)
      const deadlineRestriction = !moment(deadlineData).isSame(this.sesiProperty.tugas_deadline2) && this.sesiProperty.tugas_deadline2 !== 'Invalid date' && moment(this.sesiProperty.tugas_deadline2).isBefore(moment(), 'seconds')
      const publishedOnRestriction = !moment(publishedOnData).isSame(this.sesiProperty.publishedOn) && this.sesiProperty.publishedOn !== 'Invalid date' && moment(this.sesiProperty.publishedOn).isBefore(moment(), 'seconds')
      if (this.sesiProperty.tipe_assignment && (!this.sesiProperty.tugas_deadline2 || this.sesiProperty.tugas_deadline2 === 'Invalid date')) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'Please fill due date if you assign a task.',
        })
      } else if (deadlineRestriction || publishedOnRestriction) {
        const isTrue = deadlineRestriction ? 'due date' : 'published on'
        const publishedOnIsTrue = publishedOnRestriction ? ' or just fill it blank' : ''
        this.$notification.error({
          message: 'Sorry.',
          description: 'Please fill ' + isTrue + ' more than now' + publishedOnIsTrue + '.',
        })
      } else if (moment(this.sesiProperty.publishedOn).isAfter(moment(this.sesiProperty.tugas_deadline2), 'seconds')) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'Please fill published on less than due date.',
        })
      } else {
        this.modalVisible = true
      }
    },
  },
  created() {
    const idSession = this.$route.params.id
    // this.findWeekSlot(idSlot)
    this.$store.commit('menu/SET_STATE', {
      currentDetailScheduleMenu: ['live class'],
    })
    this.$store.dispatch('slot/FETCH_CURRENT_SLOT', { idSession })
      .then((zoomSetting) => {
        this.time = moment(`${this.currentSlot.tanggal_sesi} ${this.currentSlot.slot.model_jadwal.jam_mulai}`, 'YYYY-MM-DD HH:mm')
        this.nextTime = moment(this.time, 'HH:mm').add(this.currentSlot.slot.model_jadwal.durasi * this.currentSlot.slot.jam_pelajaran, 'minutes')
        this.sesiProperty = {
          id: this.currentSlot.id,
          zoom_setting: this.currentSlot.zoom_setting,
          tipe_assignment: this.currentSlot.tipe_assignment,
          tugas_deadline2: this.currentSlot.tugas_deadline2,
          tugas_deskripsi: this.currentSlot.tugas_deskripsi,
          pgSoalBundle: this.currentSlot.pg_soal,
          classList: [{ id_kelas: this.currentSlot.slot.kela.id }],
        }
        this.topikProperty = {
          id: this.currentSlot.topik.id,
          nama: this.currentSlot.topik.nama,
          sub_topik: this.currentSlot.topik.subtopik,
          brief: this.currentSlot.topik.brief,
          video_url1: this.currentSlot.topik.video_url1,
          video_url2: this.currentSlot.topik.video_url2,
          video_url3: this.currentSlot.topik.video_url3,
          video_url4: this.currentSlot.topik.video_url4,
          video_url5: this.currentSlot.topik.video_url5,
        }
        this.liveClass = {
          zoom_setting: this.currentSlot.zoom_setting,
          student: this.currentSlot.slot.kela.murids,
        }
        // ZOOM CONFIG
        if (zoomSetting && this.isNow) {
          this.$store.commit('SET_STATE', {
            isLoading: true,
          }, { root: true })
          this.makeDuration()

          // console.log(this.currentSlot.slot.kela)

          // this.meetConfig = {
          //   apiKey: this.currentSlot.slot.kela.zoom_api_key,
          //   apiSecret: this.currentSlot.slot.kela.zoom_api_secret,
          //   meetingNumber: this.currentSlot.slot.kela.zoom_url,
          //   userName: `${this.user.nama}`,
          //   passWord: this.currentSlot.slot.kela.zoom_password,
          //   leaveUrl: window.location.origin,
          //   role: 1,
          // }

          // this.signature = ZoomMtg.generateSignature({
          //   meetingNumber: this.meetConfig.meetingNumber,
          //   apiKey: this.meetConfig.apiKey,
          //   apiSecret: this.meetConfig.apiSecret,
          //   role: this.meetConfig.role,
          //   success: function(res) {
          //     // eslint-disable-next-line
          //     console.log("success signature: " + res.result);
          //   },
          // })

          // ZoomMtg.init({
          //   leaveUrl: window.location.origin,
          //   isSupportAV: true,
          //   isSupportChat: false,
          //   disableRecord: true,
          //   screenShare: true,
          //   // disableReport: true,
          //   success: () => {
          //     ZoomMtg.join({
          //       meetingNumber: this.meetConfig.meetingNumber,
          //       userName: this.meetConfig.userName,
          //       signature: this.signature,
          //       apiKey: this.meetConfig.apiKey,
          //       userEmail: 'iman@digitala.id',
          //       passWord: this.meetConfig.passWord,
          //       success: (res) => {
          //         this.$store.commit('SET_STATE', {
          //           isLoading: false,
          //         }, { root: true })
          //         this.$notification.success({
          //           message: 'Success',
          //           description: 'Successfully joining zoom. Good luck for your class!',
          //         })
          //         this.$store.commit('SET_STATE', {
          //           isLoading: false,
          //         }, { root: true })
          //         // eslint-disable-next-line
          //         console.log("join meeting success")
          //         localStorage.setItem('isLive', true)
          //         $('#zmmtg-body').children('#zmmtg-root').append($('.meeting-app'))
          //         $('#wc-container-left').children('div').css('height', '100%')
          //         $('#active-my-canvas').attr('style', 'height: 100%; width: 100%;')
          //         $('#wc-container-left').children('div').attr('style', 'height: 100%')
          //       },
          //       error: (res) => {
          //         this.$store.commit('SET_STATE', {
          //           isLoading: false,
          //         }, { root: true })
          //         this.$notification.error({
          //           message: 'Error',
          //           description: 'Sorry. There must be a trouble when joining class.',
          //         })
          //         // eslint-disable-next-line
          //         console.log(res);
          //       },
          //     })
          //   },
          //   error: (res) => {
          //     this.$store.commit('SET_STATE', {
          //       isLoading: false,
          //     }, { root: true })
          //     this.$notification.error({
          //       message: 'Error',
          //       description: 'Sorry. There must be a trouble when joining class.',
          //     })
          //     // eslint-disable-next-line
          //     console.log(res);
          //   },
          // })

          // $('.zm-modal-footer-default-actions').children("button:contains('End Meeting')").attr('style', 'display: none;')
        }
        return this.$store.dispatch('slot/FETCH_CLASS_ASSIGNMENT_BY_MAPEL_TEACHER', {
          idGuru: this.user.id,
          idMapel: this.currentSlot.slot.mata_pelajaran.id,
          idWeek: this.currentSlot.id_week,
          tingkat: this.currentSlot.slot.kela.tingkat,
        })

        // return 'oke'
      })
      .then(kelas => {
        this.daftarKelas = kelas
        return this.$store.dispatch('attendance/FETCH_ALL_ATTENDANCE', {
          idSession,
          idMapel: this.currentSlot.slot.mata_pelajaran.id,
          idKelas: this.currentSlot.slot.kela.id,
        })
      })
      .then(attendances => {
        this.attendances = attendances
        return this.$store.dispatch('slot/FETCH_FILE_MATERIAL', { idTopik: this.currentSlot.topik.id })
      })
  },
  computed: {
    isInSubjectHours() {
      return moment().isBetween(this.time, this.nextTime, undefined, '[]')
    },
    currentSlot() { return this.$store.state.slot.currentSlot },
    sessionDate() { return moment(this.currentSlot.tanggal_sesi, 'YYYY-MM-DD').locale('id').format('DD MMMM YYYY') },
    sessionTime() { return moment(this.currentSlot.slot.model_jadwal.jam_mulai, 'HH:mm').locale('id').format('HH:mm') },
    materials() { return this.currentSlot.topik },
    isLive() { return this.currentSlot.zoom_setting },
    user() { return this.$store.state.user.user },
    isDone() { return moment(`${this.sessionDate} ${this.sessionTime}`, 'DD MMMM YYYY HH:mm').isBefore(moment(), 'days') },
    isNow() {
      // return true
      // console.log(moment().isBetween(time, nextTime, undefined, '[]'))
      // console.log(moment().days())
      return moment().days() === this.time.days()
      // return moment().isSame(this.time, 'days') && moment().isBefore(moment('13:00', 'HH:mm')) && moment().isAfter(moment('06:00', 'HH:mm'))
    },
    videoUrls() {
      const data = []
      for (let i = 1; i <= 5; i++) {
        if (this.materials[`video_url${i}`] && this.materials[`video_url${i}`] !== '') {
          data.push(this.materials[`video_url${i}`])
        }
      }
      return data
    },
    fileMaterials() {
      return this.$store.state.slot.fileMaterials
    },

    current: {
      get: function() {
        return this.$store.state.menu.currentDetailScheduleMenu
      },
      set: function(value) {
        this.$store.commit('menu/SET_STATE', {
          currentDetailScheduleMenu: value,
        })
      },
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>
<style lang="scss">
.detailSesi {
  overflow-x: hidden;
  #coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: #008080;
    border-radius: 10px;
    padding: 40px;
    color: #ffffff;
    /* opacity: 0.8;
    z-index: 10; */
  }

  .countdownTImer {
    width: 211px;
    height: 120px;
    background-color: white;
    position: fixed;
    bottom: 10%;
    right: 5%;
    z-index: 99999;
    box-shadow: 0px 5px 15px #6d807b80;
    display: flex;
    flex-direction: column;
    padding: 30px;
    h4 {
      color: #041930;
    }
    h2 {
      margin: 0;
      color: #ff0000;
    }
  }

  h2 {
    color: #ffffff;
  }

  .menuSchedule {
    margin-bottom: 35px;
    .ant-col-20 {
      padding-right: 25px;
    }

    .ant-col-21 {
      padding-right: 10px;
    }
  }

  #contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #0419301a;
    margin-left: 1px;

    @media (max-width: 425px) {
      padding: 20px;
    }
  }

  .subMenuDetail {
    font-family: "Mukta", "sans-serif";
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 1.5px;
    /* color: #041930; */
  }

  .form-submit {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    padding: 10px 45px;
    background: #347c2c 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    border-style: none;
    color: #ffffff;
  }

  .form-submit:active {
    border-style: none;
  }
}
</style>
